import Vue from 'vue';
import Router from 'vue-router';
import AuthorisationGuard from '../guards/AuthorisationGuard.js';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      redirect: { name: 'home' },
    },
    {
      path: '',
      beforeEnter: AuthorisationGuard,
      component: () => import('@/templates/Default.vue'),
      children: [
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/Profile.vue'),
        },
      ],
    },
    {
      path: '',
      component: () => import('@/templates/Default.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home.vue'),
        },
        {
          path: '/inloggen',
          name: 'login',
          component: () => import('@/views/authorisation/Login.vue'),
        },
        {
          path: '/password/forgotten',
          name: 'password.forgotten',
          component: () => import('@/views/authorisation/PasswordForgotten.vue'),
        },
        {
          path: '/password/reset/:token',
          name: 'password.reset',
          component: () => import('@/views/authorisation/PasswordReset.vue'),
        },
        {
          path: '/invitation/accept/:token',
          name: 'invitation.accept',
          component: () => import('@/views/authorisation/InvitationAccept.vue'),
        },
        {
          path: '/registration/verify',
          name: 'registration.verify',
          component: () => import('@/views/authorisation/Verify.vue'),
        },
        {
          path: '/assortiment',
          name: 'order',
          component: () => import('@/views/CarSelector.vue'),
        },
        {
          path: '/producten',
          name: 'products',
          component: () => import('@/views/Products.vue'),
        },
        {
          path: '/product/:code',
          name: 'product',
          component: () => import('@/views/Product.vue'),
        },
        {
          path: '/bestellen-zonder-account',
          name: 'order-account',
          component: () => import('@/views/OrderAccount.vue'),
        },
        {
          path: '/bestellen',
          name: 'make-order',
          component: () => import('@/views/MakeOrder.vue'),
        },
        {
          path: '/winkelwagen',
          name: 'shopping-cart',
          component: () => import('@/views/ShoppingCart.vue'),
        },
        {
          path: '/bestelling-geplaatst',
          name: 'finished-order',
          component: () => import('@/views/FinishedOrder.vue'),
        },
        {
          path: '/faq',
          name: 'faq',
          component: () => import('@/views/faq/Layout.vue'),
          children: [
            {
              path: '/faq/:id?/:name?',
              name: 'faq.questions',
              component: () => import('@/views/faq/Details.vue'),
            },
          ],
        },
        {
          path: '/pagina',
          name: 'page-view',
          component: () => import('@/views/pageView/Layout.vue'),
          children: [
            {
              path: '/pagina/:slug',
              name: 'page-view.details',
              component: () => import('@/views/pageView/Details.vue'),
            },
          ],
        },
        {
          path: '/contact',
          name: 'contact',
          component: () => import('@/views/Contact.vue'),
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@/views/PageNotFound.vue'),
        },
        {
          path: '/403',
          name: '403',
          component: () => import('@/views/PageForbidden.vue'),
        },
        {
          path: '*',
          redirect: '/404',
        },
      ],
    },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});
