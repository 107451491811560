import Model from './model';

export class Address extends Model {
  attention = '';
  company = '';
  street = '';
  houseNumber = '';
  postalCode = '';
  city = '';
  phone ='';

  mapForRequest() {
    return this;
  }
}
