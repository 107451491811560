<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.v-input.required label::after {
  content: " *";
}
</style>
