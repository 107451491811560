import router from '@/router';

export default {
  namespaced: true,
  state: {
    token: '',
  },
  mutations: {
    setAuthorisationToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    logout(context) {
      context.commit('setAuthorisationToken', '');

      context.commit('order/clearOrder', null, { root: true });
      context.commit('profile/clearProfile', null, { root: true });

      router.push({ name: 'login' });
    },
    setToken({ commit }, token) {
      commit('setAuthorisationToken', token);
      router.push({ name: 'home' });
    },
  },
  getters: {
    isLoggedIn: state => !!state.token && !!state.token.length,
  },
};
