import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-pro/css/all.css';
import nl from 'vuetify/es5/locale/nl';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      'close': 'far fa-times',
      'menu': 'far fa-bars',
      'delete': 'far fa-trash-alt',
      'add': 'far fa-plus',
      'edit': 'far fa-pencil-alt',
      'search': 'far fa-search',
      'profile' : 'fas fa-user'
    },
  },
  customProperties: true,
  lang: {
    locales: { nl },
    current: 'nl',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#015900',
        secondary: '#383838',
        accent: '#383838',
      },
    },
  },
});
