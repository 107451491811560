import Model from './model';
import { Address } from '@/application/models/Address.js';
import { deliveryTypes } from '@/application/enums/deliveryType.js';
import router from '@/router';

export class Order extends Model {
  shippingAddress = new Address();
  invoiceAddress = new Address();
  deliverAt = null;
  paymentMethodId = null;
  paymentRedirectUrl = '';
  createUser = false;
  newsletter = false;
  isPickUp = false;
  privacyPolicy = false;
  expressDelivery = deliveryTypes.DEFAULT;
  email = '';
  phone = '';
  orderReference = ''
  orderLines = [];
  images = [];

  mapForRequest() {
    const route = router.resolve({ name: 'finished-order' });
    const redirectUrl = location.protocol + '//' + location.host + route.href;

    if (this.isPickUp) {
      this.expressDelivery = deliveryTypes.DEFAULT;
    }

    if (this.expressDelivery === deliveryTypes.EXPRESS) {
      this.deliverAt = undefined;
    }

    return {
      ...this,
      images: this.images,
      newsletter: this.newsletter ? 1 : 0,
      paymentRedirectUrl: redirectUrl,
      orderLines: this.orderLines.map(orderLine => {
        return {
          productId: orderLine.id,
          quantity: orderLine.quantity,
        };
      }),
    };
  }
}
