import Vue from 'vue';
import VueCurrencyFilter from 'vue-currency-filter';

VueCurrencyFilter.toLocaleString('nl-NL');

Vue.use(VueCurrencyFilter,
  {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    currency: 'EUR',
  });
