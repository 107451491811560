import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
/* MODULES */
import authorisation from '@/store/modules/authorisation.js';
import error from '@/store/modules/error.js';
import order from '@/store/modules/order.js';
import filter from '@/store/modules/filter.js';
import profile from '@/store/modules/profile.js';
/* END MODULES */

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState(),
  ],
  modules: {
    authorisation,
    error,
    order,
    filter,
    profile,
  },
});
