import cssVars from 'css-vars-ponyfill';
import './analytics';
import './sentry';
import './reCaptcha';
import './currency';
import '@/scss/main.scss';
import vuetify from './vuetify';

cssVars();

export { vuetify };
