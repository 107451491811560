import Model from './model';

export class Profile extends Model {
  name = '';
  email = '';
  phone = '';
  isAdmin = false;
  isBusiness = false;
  canOrderOnInvoice = false;

  mapForRequest() {
    return this;
  }
}
