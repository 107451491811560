import { Profile } from '@/application/models/Profile.js';

export default {
  namespaced: true,
  state: {
    profile: new Profile(),
  },
  mutations: {
    setProfile(state, value) {
      state.profile = value;
    },
    clearProfile(state) {
      state.profile = new Profile();
    },
  },
  getters: {
    profile: state => {
      return state.profile;
    },
  },
};
