export default {
  namespaced: true,
  state: {
    materialId: undefined,
    brand: undefined,
    model: undefined,
    implementation: undefined,
  },
  mutations: {
    setMaterialId(state, value) {
      state.materialId = value;
    },
    setBrand(state, value) {
      state.brand = value;
    },
    setModel(state, value) {
      state.model = value;
    },
    setImplementation(state, value) {
      state.implementation = value;
    },
    removeFilters(state) {
      state.brand = null;
      state.model = null;
      state.implementation = null;
    },
    setFilters(state, value) {
      state.materialId = value.materialId || state.materialId;
      state.brand = value.brand;
      state.model = value.model;
      state.implementation = value.implementation;
    },
  },
  getters: {
    filters: state => {
      return {
        materialId: state.materialId,
        brand: state.brand,
        model: state.model,
        implementation: state.implementation,
      };
    },
    selectedBrand: state => state.brand,
    selectedMaterialId: state => state.materialId,
    selectedModel: state => state.model,
    selectedImplementation: state => state.implementation,
  },
};
