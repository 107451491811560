import { Order } from '@/application/models/Order.js';

export default {
  namespaced: true,
  state: {
    addressesAreTheSame: true,
    hasFinishedPersonalTab: false,
    hasFinishedShipmentTab: false,
    hasFinishedPaymentTab: false,
    beenSyncedWithProfile: false,
    order: new Order(),
  },
  mutations: {
    setAddressesAreTheSame(state, value) {
      state.addressesAreTheSame = value;
    },
    setOrder(state, value) {
      state.order = value;
    },
    setIsPickUp(state, value) {
      state.order.isPickUp = value;
    },
    setHasFinishedPersonalTab(state, value) {
      state.hasFinishedPersonalTab = value;
    },
    setHasFinishedShipmentTab(state, value) {
      state.hasFinishedShipmentTab = value;
    },
    setHasFinishedPaymentTab(state, value) {
      state.hasFinishedPaymentTab = value;
    },
    setDeliverAt(state, value) {
      state.order.deliverAt = value;
    },
    setShippingAddress(state, value) {
      state.order.shippingAddress = value;
    },
    setInvoiceAddress(state, value) {
      state.order.invoiceAddress = value;
    },
    setEmail(state, value) {
      state.order.email = value;
    },
    setPhone(state, value) {
      state.order.phone = value;
    },
    setOrderReference(state, value) {
      state.order.orderReference = value;
    },
    setCreateUser(state, value) {
      state.order.createUser = value;
    },
    setPaymentMethod(state, value) {
      state.order.paymentMethodId = value;
    },
    setImages(state, value) {
      state.order.images = value;
    },
    setNewsletter(state, value) {
      state.order.newsletter = value;
    },
    setPrivacyPolicy(state, value) {
      state.order.privacyPolicy = value;
    },
    setBeenSyncedWithProfile(state, value) {
      state.beenSyncedWithProfile = value;
    },
    setExpressDelivery(state, value) {
      state.order.expressDelivery = value;
    },
    addOrderLine(state, value) {
      const index = state.order.orderLines.findIndex(orderLine => orderLine.id === value.id);

      if (index > -1) {
        state.order.orderLines[index].quantity++;
      } else {
        state.order.orderLines.push({
          ...value,
          quantity: 1,
        });
      }
    },
    updateOrderLineQuantity(state, value) {
      const index = state.order.orderLines.findIndex(orderLine => orderLine.id === value.product.id);
      state.order.orderLines[index].quantity = value.quantity;
    },
    updateOrderLinePrice(state, other) {
      const index = state.order.orderLines.findIndex(orderLine => orderLine.id === other.id);

      if (index > -1) {
        state.order.orderLines[index].price = other.price;
        state.order.orderLines[index].alternativePrice = other.alternativePrice;
      }
    },
    removeOrderLine(state, value) {
      const index = state.order.orderLines.findIndex(orderLine => orderLine.id === value.id);
      state.order.orderLines.splice(index, 1);
    },
    clearOrder(state) {
      state.order = new Order();
    },
    clearOrderDetails(state) {
      state.order.orderLines = [];
      state.order.images = [];
      state.order.privacyPolicy = false;
      state.order.paymentMethodId = null;
      state.order.deliverAt = null;

      state.hasFinishedPersonalTab = false;
      state.hasFinishedShipmentTab = false;
      state.hasFinishedPaymentTab = false;
    },
  },
  actions: {
    clearOrder(context) {
      context.commit('clearOrder');
    }
  },
  getters: {
    isReadyToOrder: state => {
      return state.order.email && typeof (state.order.createUser) === 'boolean';
    },
    orderLines: state => {
      return state.order.orderLines;
    },
    hasOrderLines: state => {
      return state.order.orderLines.length > 0;
    },
    shippingAddress: state => {
      return state.order.shippingAddress;
    },
    invoiceAddress: state => {
      return state.order.invoiceAddress;
    },
    paymentMethodId: state => {
      return state.order.paymentMethodId;
    },
    images: state => {
      return state.order.images;
    },
    newsletter: state => {
      return state.order.newsletter;
    },
    privacyPolicy: state => {
      return state.order.privacyPolicy;
    },
    deliverAt: state => {
      return state.order.deliverAt;
    },
    addressesAreTheSame: state => {
      return state.addressesAreTheSame;
    },
    isPickUp: state => {
      return state.order.isPickUp;
    },
    expressDelivery: state => {
      return state.order.expressDelivery;
    },
    email: state => {
      return state.order.email;
    },
    phone: state => {
      return state.order.phone;
    },
    orderReference: state => {
      return state.order.orderReference;
    },
    hasFinishedPersonalTab: state => {
      return state.hasFinishedPersonalTab;
    },
    hasFinishedShipmentTab: state => {
      return state.hasFinishedShipmentTab && state.hasFinishedPersonalTab;
    },
    hasFinishedPaymentTab: state => {
      return state.hasFinishedPaymentTab && state.hasFinishedShipmentTab && state.hasFinishedPersonalTab;
    },
    order: state => {
      return state.order;
    },
    beenSyncedWithProfile: state => {
      return state.beenSyncedWithProfile;
    },
  },
};
